import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"

const clientList = [
  "Landers McLarty / Gallatin Subaru, Gallatin, TN",
  "Nelson Mazda, Franklin, Nashville & Murfreesboro, TN",
  "City Auto, Memphis & Murfreesboro, TN",
  "Chuck Hutton Toyota, Memphis, TN",
  "Southern Thunder Harley Davidson, Southaven, MS",
  "Bumpus Harley Davidson in Collierville & Murfreesboro, TN",
  "Rock City Harley Davidson, Little Rock, AR",
  "Doolins Harley Davidson, Texarkana, TX",
  "Heart of Dixie Harley Davidson, Pelham, AL",
  "Tri State Truck ( Mack) in Jackson Ms, & Tupelo, MS",
  "Duece McCallister Imports, Jackson, MS",
  "Batesville Ford, Batesville, MS",
  "Walt Massey Dodge, Lucedale, MS",
  "Ben Freeland Chevrolet, Nashville, TN",
  "Ben Freeland Dodge, Nashville, TN",
  "Freeland Auto Recyclers, Columbia, TN",
  "Serra Chevrolet, Nashville, TN",
  "DriveNow/ AutoMasters Re-Con Center, Nashville, TN",
  "Barnes Crossings Automotive Group, Tupelo, MS",
  "Bowling Green Toyota, Bowling Green, KY",
  "Stan McNabb Chevrolet, Tullahoma, TN",
  "Grayson BMW, Knoxville, TN",
  "Eastern Shore KIA, Atlanta, GA",
  "Rush Peterbilt,  Nashville, TN",
  "Covington Detroit Diesel, Nashville, TN",
  "Big G Express, Nashville,  TN",
  "Titan Transfer, Pulaski, TN",
  "McCormick Trucking, Murfreesboro, TN",
  "Ford of Cookeville, TN",
  "Hyundai of Cookeville, TN",
  "Nissan of Columbia, TN",
  "Grayline Tn. Bus, Nashville, TN",
  "Bob Frensley Dodge, Nashville, TN",
  "Don Ledford GM, Cleveland, TN",
  "James Corlew Chevrolet, Clarksville, TN",
  "The Victory Automotive Group, Various locations",
  "Cool Springs Toyota, Franklin, TN",
  "Jerry Damson Dodge, Arab, AL",
  "Jerry Damson Honda, Huntsville, AL",
  "The AMSI Automotive Group, Various locations, TN",
  "Newton Automotive Group, Various locations, TN",
  "Miller Wrecker Bodies, Various Locations, TN",
]

export default function PastCustomers() {
  return (
    <>
      <Layout>
        <SEO title="Past Customers - Choice Automotive Equpment" />
        <div className="bg-light p-4">
          <h2 className="mr-auto ml-auto text-center">
            CUSTOMERS WE'VE SERVED
          </h2>
          <ul>
            {clientList.map(client => <li>{client}</li>)}
          </ul>
        </div>
      </Layout>
    </>
  )
}
